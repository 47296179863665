<template>
  <div>
    <h3>{{ $t("module.meta.fields.default_timespan") }}</h3>
    <v-btn-toggle
      class="flex-wrap"
      v-model="timespan"
      color="primary"
      dense
      mandatory
    >
      <v-btn v-for="t in timespans" :key="t.value" :value="t.value">
        <v-icon small class="mr-1">mdi-calendar-range</v-icon>
        {{ t.name }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: "StandardTimeSpanSelector",

  props: {
    value: {
      default: String,
      require: true,
    },

    shortWords: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "value",
  },

  data() {
    return {
      timespans: [
        {
          name: this.shortWords
            ? this.$t("common.dateRangeShort.0")
            : this.$t("common.dateRange.0"),
          value: "hour",
        },
        {
          name: this.shortWords
            ? this.$t("common.dateRangeShort.1")
            : this.$t("common.dateRange.1"),
          value: "5_hour",
        },
        {
          name: this.shortWords
            ? this.$t("common.dateRangeShort.2")
            : this.$t("common.dateRange.2"),
          value: "24_hour",
        },
        {
          name: this.shortWords
            ? this.$t("common.dateRangeShort.3")
            : this.$t("common.dateRange.3"),
          value: "7_days",
        },
        {
          name: this.shortWords
            ? this.$t("common.dateRangeShort.4")
            : this.$t("common.dateRange.4"),
          value: "30_days",
        },
      ],
    };
  },

  computed: {
    timespan: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
