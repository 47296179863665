<template>
  <div v-if="isMetaBool(mKey)">
    <v-switch
      v-model="boolValue"
      :label="name"
      hide-details
      small
      :key="mKey + getMetaValueAsBoolWithSlot(mKey, slotId) + slotId"
    />
  </div>

  <div v-else class="mt-2">
    <v-text-field
      outlined
      dense
      hide-details
      v-if="shouldHaveValueInput(mKey)"
      :label="labelForMeta(mKey, 0)"
      prepend-inner-icon="mdi-alpha-v"
      :value="getMeta(mKey, slotId)?.value"
      @change="(val) => addMeta(mKey, val, slotId)"
    />

    <v-text-field
      outlined
      dense
      hide-details
      v-if="shouldHaveValueInputNumber(mKey)"
      :label="labelForMeta(mKey, 0)"
      prepend-inner-icon="mdi-alpha-v"
      :value="getMeta(mKey, slotId)?.value"
      @change="(val) => addMeta(mKey, val, slotId)"
    />

    <v-text-field
      outlined
      dense
      hide-details
      v-if="shouldHaveContent(mKey)"
      :label="labelForMeta(mKey, 1)"
      prepend-inner-icon="mdi-alpha-n"
      :value="getMeta(mKey, slotId)?.content"
      @change="(val) => addMeta(mKey, val, slotId, 'content')"
    />

    <v-select
      v-if="shouldHaveIcon(mKey)"
      :items="moduleMeta.Icons"
      :label="$t('module.meta.icon')"
      :value="getMeta(mKey, slotId)?.value"
      @change="(val) => addMeta(mKey, val, slotId)"
      prepend-inner-icon="mdi-auto-fix"
      outlined
      dense
      hide-details
    >
      <template slot="item" slot-scope="data">
        <v-icon small>{{ data.item }}</v-icon>
      </template>
      <template slot="selection" slot-scope="data">
        <v-icon medium>{{ data.item }}</v-icon>
      </template>
    </v-select>

    <v-autocomplete
      @focus="getAllKeys()"
      :label="name"
      :items="keys"
      :value="getMeta(mKey, slotId)?.content"
      @change="(val) => addMeta(mKey, val, slotId, 'content')"
      required
      item-text="label"
      item-value="key"
      prepend-inner-icon="mdi-key"
      autocomplete="off"
      clearable
      outlined
      dense
      hide-details
      v-if="
        mKey == metaKeys.TAGKEY ||
        mKey == metaKeys.TAGKEY_X ||
        mKey == metaKeys.TAGKEY_X_MAX ||
        mKey == metaKeys.TAGKEY_Y ||
        mKey == metaKeys.TAGKEY_Y_MAX
      "
    />

    <v-autocomplete
      outlined
      dense
      @focus="getAlarms()"
      :label="$t('module.meta.fields.alarm_id')"
      :value="getMeta(mKey, slotId)?.content"
      @change="(val) => addMeta(mKey, val, slotId, 'content')"
      :items="alarms"
      required
      item-text="name"
      item-value="alarmId"
      prepend-inner-icon="mdi-alarm"
      autocomplete="off"
      clearable
      hide-details
      v-if="mKey == metaKeys.ALARM_ID"
    />

    <v-autocomplete
      @focus="getScripts()"
      autocomplete="off"
      :loading="scriptStatus.loading"
      :label="$t('script.name')"
      :value="getMeta(mKey, slotId)?.value"
      @change="(val) => addMeta(mKey, val, slotId)"
      :items="calledScripts"
      item-text="name"
      item-value="scriptId"
      prepend-inner-icon="mdi-cellphone-link"
      clearable
      outlined
      dense
      hide-details
      v-if="mKey == metaKeys.SCRIPTID"
    />

    <v-autocomplete
      @focus="getDashboard()"
      autocomplete="off"
      :label="$t('dashboard.name')"
      :value="getMeta(mKey, slotId)?.value"
      @change="(val) => addMeta(mKey, val, slotId)"
      :items="dashboards"
      item-text="name"
      item-value="dashboardId"
      prepend-inner-icon="mdi-view-dashboard-variant"
      clearable
      outlined
      dense
      hide-details
      v-if="mKey == metaKeys.DASHBOARDID"
    />

    <div v-if="mKey == metaKeys.FONT_STYLE">
      <v-autocomplete
        autocomplete="off"
        :label="$t('module.meta.fields.font_style_large.type')"
        :items="fontTypes"
        item-text="name"
        item-value="value"
        prepend-inner-icon="mdi-format-color-text"
        clearable
        outlined
        dense
        hide-details
        :value="getMeta(mKey, slotId)?.content"
        @change="(val) => addMeta(mKey, val, slotId, 'content')"
      >
      </v-autocomplete>

      <v-autocomplete
        class="mt-2"
        autocomplete="off"
        :label="$t('module.meta.fields.font_style_large.size')"
        :items="fontSizes"
        prepend-inner-icon="mdi-format-color-text"
        clearable
        outlined
        dense
        hide-details
        :value="getMeta(mKey, slotId)?.value"
        @change="(val) => addMeta(mKey, val, slotId)"
      >
        <template v-slot:item="item">
          <span :style="'font-size: ' + item.item + 'rem'"> Text </span>
        </template>
      </v-autocomplete>
    </div>

    <v-autocomplete
      v-if="mKey === metaKeys.FONT_WEIGHT"
      autocomplete="off"
      :label="$t('module.meta.fields.font_style_large.weight')"
      :items="fontWeights"
      item-text="name"
      item-value="value"
      prepend-inner-icon="mdi-format-color-text"
      clearable
      outlined
      dense
      hide-details
      :value="getMeta(mKey, slotId)?.value"
      @change="(val) => addMeta(mKey, val, slotId)"
    >
      <template v-slot:item="data">
        <span :class="data.item.value">
          {{ data.item.name }}
        </span>
      </template>
    </v-autocomplete>

    <font-form
      v-if="
        isType([metaKeys.FONT_STYLE_TITLE, metaKeys.FONT_STYLE_CONTENT], mKey)
      "
      v-model="widget"
      :m-key="mKey"
    />

    <chart-type-selector
      v-if="mKey === metaKeys.CHART_TYPE"
      :value="getMeta(mKey, slotId)?.value"
      @input="(val) => addMeta(mKey, val, slotId)"
    />

    <standard-time-span-selector
      v-if="mKey === metaKeys.DEFAULT_TIMESPAN"
      :value="getMeta(mKey, slotId)?.value"
      @input="(val) => addMeta(mKey, val, slotId)"
      shortWords
    />

    <cam-online-form
      v-if="mKey === metaKeys.CAMONLINE"
      :meta="widget.meta"
      @input="(val) => (widget.meta = val)"
    />

    <value-match-form v-if="mKey === metaKeys.VALUEMATCH" v-model="widget" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import widgetMixin from "../../../_helpers/widgetMixin";
import CamOnlineForm from "../../camonline/CamOnlineForm.vue";
import ChartTypeSelector from "./ChartTypeSelector.vue";
import StandardTimeSpanSelector from "./StandardTimeSpanSelector.vue";
import FontForm from "./FontForm.vue";
import ValueMatchForm from "./ValueMatchForm.vue";

export default {
  name: "WidgetMetaField",

  components: {
    ChartTypeSelector,
    StandardTimeSpanSelector,
    CamOnlineForm,
    FontForm,
    ValueMatchForm,
  },

  props: {
    slotId: {
      type: String,
      default: "",
    },

    name: {
      type: String,
      default: "",
      required: true,
    },

    mKey: {
      type: String,
      default: "",
      required: true,
    },

    mdlWidget: {
      default: () => {},
      required: true,
    },
  },

  data() {
    return {
      k: 0,
    };
  },

  model: { prop: "mdlWidget" },

  mixins: [widgetMixin],

  methods: {
    ...mapActions("alarms", ["getAlarms"]),
    ...mapActions("tag", ["getAllKeys"]),
    ...mapActions("scripts", ["getScripts"]),
    ...mapActions("dashboards", ["getDashboards"]),
  },

  computed: {
    ...mapState("tag", ["keys"]),
    ...mapState("alarms", ["alarms"]),
    ...mapState("scripts", ["scripts"]),
    ...mapState("dashboards", ["dashboards"]),

    // This is needed for mixin to work
    widget: {
      get() {
        return this.mdlWidget;
      },

      set(val) {
        this.$emit("input", val);
      },
    },

    boolValue: {
      get() {
        return this.getMetaValueAsBoolWithSlot(this.mKey, this.slotId);
      },

      set(val) {
        this.addMeta(this.mKey, val, this.slotId);
      },
    },
  },
};
</script>
